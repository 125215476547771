import { PageHeadline } from "components/typography.styles";
import styled from "styled-components";

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  color: white;
  min-height: 150px;
  padding: 20px;
  z-index: 1;

  &:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: 200px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    background-size: 960px;

    &:before {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      width: 960px;
    }
  }

  ${PageHeadline} {
    margin-top: 30px;

    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-top: 0;
    }
  }
`;

export const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 960px;
    left: auto;
    right: auto;
  }
`;
