import styled from "styled-components";
import { FlexContent } from "../../FlexContent/FlexContent";
import { MediumParagraph } from "components/typography.styles";

export const StyledLogo = styled.img<{ isDuende?: boolean }>`
  max-width: 100px;
  position: absolute;
  top: ${({ isDuende }) => (isDuende ? "5px" : "10px")};
  left: 10px;
  max-height: ${({ isDuende }) => (isDuende ? "auto" : "30px")};

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 120px;
    max-height: ${({ isDuende }) => (isDuende ? "auto" : "40px")};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 150px;
    top: ${({ isDuende }) => (isDuende ? "5px" : "20px")};
    left: 20px;
    max-height: ${({ isDuende }) => (isDuende ? "auto" : "60px")};
  }
`;

export const BookingContent = styled(FlexContent)`
  padding: 25px 16px 100px 16px;
  background: white;
  width: 100%;
  min-height: calc(100vh - 215px);

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 40px;
    max-width: 960px;
    margin: 0 auto;
  }
`;

export const StyledBookingContainer = styled.div`
  padding-bottom: 20px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: 0;
  }
`;

export const BookingHeader = styled(FlexContent)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: auto;
    svg {
      margin-left: 20px;
    }
  }
`;

export const StyledBooking = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.gray100};
`;
export const BookingElement = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  width: 100%;
`;

export const EventPrice = styled(FlexContent)<{ isPromo?: boolean }>`
  color: ${({ theme, isPromo }) =>
    isPromo ? theme.colors.red : theme.colors.black};
`;

export const EventDetailsWrapper = styled(FlexContent)`
  flex-wrap: wrap;

  ${EventPrice} {
    width: 100%;
    margin-top: 12px;
  }

  ${({ theme }) => theme.breakpoints.up("xs")} {
    flex-wrap: nowrap;

    ${EventPrice} {
      width: auto;
      margin-top: 0;
    }
  }
`;

export const LabelWrapper = styled.div`
  position: absolute;
  top: -11px;
  right: 15px;
`;

export const PromoLabelWrapper = styled.div`
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Line = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.black};
  width: 100%;
`;
export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 2px solid black;
`;
export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 40px;
`;
export const RadioButton = styled.input`
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  &:checked + ${RadioButtonLabel} {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
      background: black;
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }
  }
`;
export const CheckboxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background: white;
  border: 1px solid black;
  border-radius: 2px;
`;

export const CheckboxButton = styled.input`
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-right: 10px;

  &:checked + ${CheckboxLabel} {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background: black;
      transform: translate(-50%, -50%);
      border-radius: 2px;
    }
  }
`;
export const CheckboxContainer = styled(FlexContent)`
  p {
    margin: -3px 8px 0 0;
  }
`;

export const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${({ theme }) => theme.colors.yellow40};
  font-size: 12px;
  line-height: 1.2;
  width: 100%;
  border-radius: 6px;

  strong {
    font-weight: 700;
  }
  svg {
    max-width: 20px;
    margin-right: 16px;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }
`;

export const StyledCalendarIcon = styled.div`
  cursor: pointer;
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const PeopleCounterWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: calc(50% - 12px);
  }
`;

export const DrinkCounterWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: calc(60% - 12px);
  }

  .react-select-containexr {
    width: 200px;
  }
`;
export const DrinkButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.yellow};
  text-decoration: underline;
`;

export const MinimizedText = styled(MediumParagraph)<{ isFull: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up("md")} {
    //reset styles
    -webkit-line-clamp: unset;
    overflow: unset;
    cursor: unset;
  }

  ${({ isFull }) =>
    isFull &&
    `
    -webkit-line-clamp: unset;
    overflow: unset;
  `}
`;

export const MinimizedTextMore = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray80};
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

export const ZoneDescription = styled.ul`
  margin: 10px 0 10px 13px;
`;
