import { BigHeadline, BigLabel, MediumLabel } from "../typography.styles";
import dayjs from "dayjs";
import { StyledDayEvent, WarningElement } from "./styles";
import { FlexContent } from "../FlexContent/FlexContent";
import { WarningIcon } from "../icons/WarningIcon";
import { PromoLabelWrapper } from "components/views/Booking/styles";
import { PromoLabel } from "components/promoLabel";
import { FormattedMessage } from "react-intl";

type TimeTileProps = {
  time: string;
  isDate?: boolean;
  isActive: boolean;
  onClick: () => void;
  hasWarning?: boolean;
  isPromo?: boolean;
};

export const TimeTile = ({
  onClick,
  time,
  isDate,
  isActive,
  hasWarning,
  isPromo,
}: TimeTileProps) => {
  return (
    <StyledDayEvent onClick={onClick} withArrow={isDate} isActive={isActive}>
      {isPromo && !hasWarning && (
        <PromoLabelWrapper>
          <PromoLabel>
            <FormattedMessage defaultMessage="Promo" id="0+KDB8" />!
          </PromoLabel>
        </PromoLabelWrapper>
      )}
      {isDate ? (
        <>
          <MediumLabel>{dayjs(time).format("ddd")}</MediumLabel>
          <BigHeadline isSerifFont>{dayjs(time).format("DD")}</BigHeadline>
          <MediumLabel>{dayjs(time).format("MMM")}</MediumLabel>
        </>
      ) : (
        <FlexContent alignItems="center" justify="space-between">
          <BigLabel fontWeight={700}>{time}</BigLabel>

          {hasWarning && (
            <WarningElement>
              <WarningIcon />
            </WarningElement>
          )}
        </FlexContent>
      )}
    </StyledDayEvent>
  );
};
