import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/ca";
import "dayjs/locale/ja";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCALES } from "../i18n/locales";
import { localeMessages } from "../i18n/messages";
type LanguageContextType = {
  locale: string;
  selectLanguage: (newLocale: string) => void;
};
export const LanguageContext = React.createContext<LanguageContextType | null>(
  null,
);
const SupportedLanguages = Object.values(LOCALES);
const DEFAULT_LOCALE = LOCALES.ENGLISH;

function determineLocale() {
  const pathSegments = location.pathname.split("/");
  const localeInPath = pathSegments[1];
  if (SupportedLanguages.includes(localeInPath)) {
    return localeInPath;
  }

  return DEFAULT_LOCALE;
}

function determineNativeLocale() {
  const browserLanguage = navigator.language.split("-")[0];
  return SupportedLanguages.includes(browserLanguage)
    ? browserLanguage
    : DEFAULT_LOCALE;
}

const LangProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [locale, setLocale] = useState(determineLocale);
  const [messages, setMessages] = useState(localeMessages[locale]);

  useEffect(() => {
    const currentLocale = determineLocale();
    if (currentLocale !== locale) {
      setLocale(currentLocale);
      setMessages(localeMessages[currentLocale]);
    }
  }, [location.pathname]);

  // Adding the function to the dependencies ensures that it will only be excecuted once
  useEffect(() => {
    if (locale === LOCALES.ENGLISH) {
      const nativeLocale = determineNativeLocale();
      selectLanguage(nativeLocale);
    }
  }, [determineNativeLocale]);

  useEffect(() => {
    console.log("Setting dayjs locale to", locale);
    dayjs.locale(locale);
  }, [locale]);

  const selectLanguage = (newLocale: string) => {
    setLocale(newLocale);
    setMessages(localeMessages[newLocale]);
    const { pathname, search } = location;

    const pathSegments = pathname.split("/");
    const localeInPath = pathSegments[1];
    let pathWithoutLang = pathname;

    if (SupportedLanguages.includes(localeInPath)) {
      pathWithoutLang = "/" + pathSegments.slice(2).join("/");
    }

    const newPath =
      newLocale === DEFAULT_LOCALE
        ? pathWithoutLang
        : `/${newLocale}${pathWithoutLang}`;
    navigate(newPath + search);
  };
  return (
    <LanguageContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LangProvider;
