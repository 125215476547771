import { Merged } from "components/views/Booking/Merges";
import { PaymentLink } from "components/views/Booking/PaymentLink";
import { TermsConditions } from "components/views/TermsConditions";
import { LOCALES } from "i18n/locales";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Booking } from "./components/views/Booking/Booking";
import { Form } from "./components/views/Booking/Form";
import { PaymentResult } from "./components/views/Booking/PaymentResult";
import { Summary } from "./components/views/Booking/Summary";
import { ErrorPage } from "./components/views/ErrorPage/ErrorPage";
import { PAYMENT_LINK, STEP_FORM, STEP_SUMMARY } from "./consts";
import { AppProviders } from "./providers/AppProvider";

const SupportedLanguages = Object.values(LOCALES).filter(
  (lang) => lang !== LOCALES.ENGLISH,
);

function LocalizedRoutes({ lang }) {
  const navigate = useNavigate();

  const localizedNavigate = (to) => {
    const newPath = lang ? { ...to, pathname: `/${lang}` } : to;
    navigate(newPath);
  };

  return (
    <Routes>
      <Route path={`/`} element={<Booking navigate={localizedNavigate} />}>
        <Route index element={<Merged />} />
        <Route path={STEP_FORM} element={<Form />} />
        <Route path={STEP_SUMMARY} element={<Summary />} />
        <Route path={PAYMENT_LINK} element={<PaymentLink />} />
      </Route>
      <Route path={"/payment-error"} element={<PaymentResult type="error" />} />
      <Route path={"/terms-and-conditions"} element={<TermsConditions />} />
      <Route
        path={"/payment-success"}
        element={<PaymentResult type="success" />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
function App() {
  return (
    <AppProviders>
      <Routes>
        {SupportedLanguages.map((lang) => (
          <Route
            key={lang}
            path={`/${lang}/*`}
            element={<LocalizedRoutes lang={`${lang}`} />}
          />
        ))}
        <Route path="/*" element={<LocalizedRoutes lang={""} />} />
      </Routes>
    </AppProviders>
  );
}

export default App;
