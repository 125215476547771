import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Select from "react-select";
import { BOOKING_STEPS, STEP_FORM } from "../../../consts";
import { countriesAll } from "../../../consts/nationalities";
import { BookingContext } from "../../../providers/BookingProvider";
import { FormType, SelectType } from "../../../providers/BookingProvider.types";
import { FlexContent } from "../../FlexContent/FlexContent";
import { Spacer } from "../../spacer";
import {
  AdjustLink,
  MediumLabel,
  MediumParagraph,
  SectionHeadline,
} from "../../typography.styles";
import { FormError, StyledField, StyledForm, StyledInput } from "./Form.styles";
import { CheckboxButton, CheckboxContainer, CheckboxLabel } from "./styles";
import { LanguageContext } from "providers/LangProvider";

export const Form = () => {
  const {
    // activeProduct,
    nextStepEnabled,
    enableNextStep,
    formData,
    setFormData,
    setActiveStep,
    isDuende,
    hotel,
  } = useContext(BookingContext);
  const [countries, setCountries] = useState<SelectType[]>([]);
  const [showNif, setNifVisible] = useState(false);
  const [showHotel, setHotelVisible] = useState(false);

  useEffect(() => {
    setActiveStep(BOOKING_STEPS.indexOf(STEP_FORM));
  }, []);

  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    const c = countriesAll.map((country) => ({
      value: country.value,
      label: country[`label_${locale}`],
    }));
    setCountries(c);
  }, [locale]);

  const {
    register,
    unregister,
    formState: { isValid, errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormType>({
    mode: "onBlur",
    defaultValues: formData,
  });

  const [
    email,
    emailConfirm,
    name,
    phone,
    nationality,
    comments,
    termsConditions,
    vegan,
    wheelchair,
    isCompany,
    nif,
    hotelName,
  ] = watch(
    [
      "email",
      "emailConfirm",
      "name",
      "phone",
      "nationality",
      "comments",
      "termsConditions",
      "vegan",
      "wheelchair",
      "isCompany",
      "nif",
      "hotelName",
    ],
    {
      email: "",
      emailConfirm: "",
      name: "",
      phone: "",
      nationality: "",
      comments: "",
      termsConditions: false,
      vegan: false,
      wheelchair: false,
      isCompany: false,
      nif: "",
      hotelName: "",
    },
  );

  useEffect(() => {
    setFormData({
      ...formData,
      email,
      emailConfirm,
      name,
      phone,
      nationality,
      comments,
      termsConditions,
      vegan,
      wheelchair,
      isCompany,
      nif,
      hotelName,
    });
  }, [
    email,
    emailConfirm,
    name,
    phone,
    nationality,
    comments,
    termsConditions,
    vegan,
    wheelchair,
    isCompany,
    nif,
    hotelName,
  ]);

  useEffect(() => {
    if (!isValid && nextStepEnabled) {
      enableNextStep(false);
    }
    if (isValid && nationality) {
      enableNextStep(true);
    }
  }, [isValid, nextStepEnabled, nationality]);

  const { formatMessage } = useIntl();

  return (
    <>
      <SectionHeadline>
        {isDuende ? "4. " : "3. "}
        <FormattedMessage
          defaultMessage="Complete Your Reservation"
          id="kFm9du"
        />
      </SectionHeadline>
      <Spacer mt={{ xs: 24 }} />
      <StyledForm id="form">
        <StyledField>
          <label>
            <FormattedMessage
              defaultMessage="First and Last name"
              id="Udes+A"
            />
          </label>
          <StyledInput {...register("name", { required: true })} />
          {errors.name?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage defaultMessage="Nationality" id="4FsZv8" />
          </label>

          <Select
            placeholder={formatMessage({
              defaultMessage: "Select your country",
              id: "EoIFbQ",
            })}
            className="react-select-container"
            defaultValue={countries.find(
              (country) => country.value === formData.nationality,
            )}
            options={countries.sort((a, b) => a.label.localeCompare(b.label))}
            classNamePrefix="react-select"
            onChange={(e: SelectType) => {
              return setValue("nationality", e.value);
            }}
          />
          {isValid && !nationality && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage defaultMessage="E-mail address" id="kIW/Ua" />
          </label>
          <StyledInput
            {...register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          {errors.email?.type === "pattern" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Incorrect email address"
                id="5o+ZyZ"
              />
            </FormError>
          )}
          {errors.email?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
          {errors.emailConfirm?.type === "sameEmail" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Email addresses do not match"
                id="DCRJgA"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage defaultMessage="Confirm e-mail" id="XaowG7" />
          </label>
          <StyledInput
            {...register("emailConfirm", {
              required: true,
              validate: {
                sameEmail: (value) => value === getValues().email,
              },
            })}
          />
          {errors.emailConfirm?.type === "pattern" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Incorrect email address"
                id="5o+ZyZ"
              />
            </FormError>
          )}
          {errors.emailConfirm?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
          {errors.emailConfirm?.type === "sameEmail" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Email addresses do not match"
                id="DCRJgA"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage defaultMessage="Phone number" id="jdJhOL" />
          </label>
          <StyledInput
            {...register("phone", {
              required: true,
              pattern: {
                value: /^[0-9 ()+-]+$/,
                message: "Entered value does not match email format",
              },
            })}
          />
          {errors.phone?.type === "pattern" && (
            <FormError>
              <FormattedMessage
                defaultMessage="Incorrect phone number"
                id="zOgguc"
              />
            </FormError>
          )}
          {errors.phone?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </StyledField>
        <StyledField>
          <label>
            <FormattedMessage
              defaultMessage="Comments: Let us know any special requirements or preferences."
              id="TbItA0"
            />
          </label>
          <StyledInput {...register("comments", { maxLength: 250 })} />
          {errors.comments?.type === "maxLength" && (
            <FormError>
              <FormattedMessage defaultMessage="Too long comment" id="4bovLw" />
            </FormError>
          )}
        </StyledField>

        <FlexContent
          direction="column"
          alignItems="flex-start"
          justify="flex-start"
          fullWidth
          padding="0 0 0 8px"
        >
          <Spacer mt={{ xs: 16, md: 32 }} />

          <>
            <MediumLabel fontWeight={700}>
              <FormattedMessage
                defaultMessage="Additional needs and requests"
                id="mZXen3"
              />
            </MediumLabel>
            <Spacer mt={{ xs: 16, md: 24 }} />
            <FlexContent direction="column" alignItems="flex-start">
              <CheckboxContainer alignItems="flex-start">
                <FlexContent justify="center" alignItems="center">
                  <CheckboxButton
                    onChange={(event) =>
                      event.target.checked
                        ? setNifVisible(event.target.checked)
                        : [
                            setNifVisible(event.target.checked),
                            unregister("nif"),
                          ]
                    }
                    type="checkbox"
                    name="isCompany"
                  />
                  <CheckboxLabel />
                </FlexContent>
                <MediumParagraph color="black">
                  <FormattedMessage
                    defaultMessage="I need an invoice"
                    id="V+mEz3"
                  />
                </MediumParagraph>
              </CheckboxContainer>

              {showNif && (
                <StyledField style={{ width: "100%", marginLeft: "20px" }}>
                  <label>
                    <FormattedMessage defaultMessage="NIF number" id="G9jeNe" />
                  </label>
                  <StyledInput
                    {...register("nif", { required: true, maxLength: 12 })}
                  />
                  {errors.nif?.type === "required" && (
                    <FormError>
                      <FormattedMessage
                        defaultMessage="This field is required"
                        id="TKmub+"
                      />
                    </FormError>
                  )}

                  {errors.nif?.type === "maxLength" && (
                    <FormError>
                      <FormattedMessage
                        defaultMessage="Incorrect format"
                        id="gDfepg"
                      />
                    </FormError>
                  )}
                </StyledField>
              )}
              {!hotel && (
                <>
                  <Spacer mt={{ xs: 8, md: 16 }} />
                  <CheckboxContainer alignItems="flex-start">
                    <FlexContent justify="center" alignItems="center">
                      <CheckboxButton
                        onChange={(event) =>
                          event.target.checked
                            ? setHotelVisible(event.target.checked)
                            : [
                                setHotelVisible(event.target.checked),
                                unregister("hotelName"),
                              ]
                        }
                        type="checkbox"
                        name="isCompany"
                      />
                      <CheckboxLabel />
                    </FlexContent>
                    <MediumParagraph color="black">
                      <FormattedMessage
                        defaultMessage="Hotel reservation"
                        id="eyLtKY"
                      />
                    </MediumParagraph>
                  </CheckboxContainer>
                  {showHotel && (
                    <StyledField style={{ width: "100%", marginLeft: "20px" }}>
                      <label>
                        <FormattedMessage
                          defaultMessage="Hotel name"
                          id="uta34T"
                        />
                      </label>
                      <StyledInput
                        style={{ width: "100%" }}
                        {...register("hotelName", {
                          required: true,
                          maxLength: 12,
                        })}
                      />
                      {errors.hotelName?.type === "required" && (
                        <FormError>
                          <FormattedMessage
                            defaultMessage="This field is required"
                            id="TKmub+"
                          />
                        </FormError>
                      )}
                    </StyledField>
                  )}
                </>
              )}

              <Spacer mt={{ xs: 8, md: 16 }} />
            </FlexContent>

            {!isDuende && (
              <>
                <CheckboxContainer alignItems="flex-start">
                  <FlexContent justify="center" alignItems="center">
                    <CheckboxButton
                      onChange={(event) =>
                        setValue("wheelchair", event.target.checked)
                      }
                      type="checkbox"
                      name="wheelchair"
                      {...register("wheelchair")}
                    />
                    <CheckboxLabel />
                  </FlexContent>
                  <MediumParagraph color="black">
                    <FormattedMessage
                      defaultMessage="Wheelchair access"
                      id="sTRKZY"
                    />
                    {/* IMPORTANT Wheelchair: we have an elevator for standard
                  wheelchairs [non-motorized] or for people with difficulty of
                  climbing stairs. The measurements of our elevator are as
                  follows: Elevator door: 70 CM. Internal dimension: 90CM X
                  90CM. */}
                  </MediumParagraph>
                </CheckboxContainer>
                <Spacer mt={{ xs: 8, md: 16 }} />
                <CheckboxContainer alignItems="flex-start">
                  <FlexContent justify="center" alignItems="center">
                    <CheckboxButton
                      onChange={(event) =>
                        setValue("vegan", event.target.checked)
                      }
                      type="checkbox"
                      name="vegan"
                      {...register("vegan")}
                    />
                    <CheckboxLabel />
                  </FlexContent>
                  <MediumParagraph color="black">
                    <FormattedMessage defaultMessage="Vegan menu" id="4bb5KE" />
                  </MediumParagraph>
                </CheckboxContainer>
                <Spacer mt={{ xs: 8, md: 40 }} />
              </>
            )}
          </>

          <MediumLabel fontWeight={700}>
            <FormattedMessage
              defaultMessage="Terms and conditions (mandatory)"
              id="7UMarG"
            />
          </MediumLabel>
          <Spacer mt={{ xs: 16, md: 24 }} />
          <CheckboxContainer alignItems="flex-start">
            <FlexContent justify="center" alignItems="center">
              <CheckboxButton
                onChange={(event) =>
                  setValue("termsConditions", event.target.checked)
                }
                type="checkbox"
                name="radio"
                {...register("termsConditions", { required: true })}
              />
              <CheckboxLabel />
            </FlexContent>
            <MediumParagraph color="black">
              <FormattedMessage defaultMessage="I accept the " id="MVn4MT" />
              <AdjustLink>
                <Link to="/terms-and-conditions">
                  <FormattedMessage
                    defaultMessage="Terms and Conditions of Use. "
                    id="5GzJ25"
                  />
                </Link>
              </AdjustLink>
              <FormattedMessage
                defaultMessage="Please note that additional fees may be applied by payment providers such as PayPal or banks"
                id="+PrzCu"
              />
            </MediumParagraph>
          </CheckboxContainer>

          {errors.termsConditions?.type === "required" && (
            <FormError>
              <FormattedMessage
                defaultMessage="This field is required"
                id="TKmub+"
              />
            </FormError>
          )}
        </FlexContent>
      </StyledForm>
    </>
  );
};
