import { Spacer } from "components/spacer";
import { MediumParagraph } from "components/typography.styles";
import { LOCALES } from "i18n/locales";
import { useIntl } from "react-intl";

export const SeoText = () => {
  const { locale } = useIntl();

  if (locale === LOCALES.CATALAN) {
    return (
      <>
        <MediumParagraph textAlign="center">
          Endinsa&#39;t en el món del flamenc a{" "}
          <strong>El Duende by Tablao Cordobés</strong>, un espai autèntic
          situat al cor de La Rambla, Barcelona. Submergeix-te en els ritmes
          vibrants i les apassionades actuacions de ballarins, cantants i músics
          de classe mundial, i viu l&#39;essència autèntica de la cultura
          espanyola.
        </MediumParagraph>
        <Spacer mt={{ xs: 10 }} />
        <MediumParagraph textAlign="center">
          Siguis local o visitant, el nostre{" "}
          <strong>espectacle de flamenc</strong> t&#39;ofereix una atmosfera
          única i íntima on podràs gaudir de l&#39;art del flamenc de prop. Tria
          entre les nostres zones de seients premium i gaudeix d&#39;una beguda
          de cortesia, amb la sangria com la nostra proposta estrella per
          completar la teva vetllada.
        </MediumParagraph>
        <Spacer mt={{ xs: 10 }} />
        <MediumParagraph textAlign="center">
          No et perdis aquesta experiència inoblidable. Reserva les teves
          entrades per a El Duende by Tablao Cordobés avui mateix i
          assegura&#39;t un lloc en un{" "}
          <strong>dels millors espectacles de flamenc de Barcelona</strong>.
          Places limitades per a una experiència íntima i immersiva. Reserva
          ara!
        </MediumParagraph>
      </>
    );
  }

  if (locale === LOCALES.FRENCH) {
    return (
      <>
        <MediumParagraph textAlign="center">
          Plongez dans l&#39;univers du flamenco à{" "}
          <strong>El Duende by Tablao Cordobés</strong>, un lieu authentique
          situé au cœur de La Rambla, à Barcelone. Imprégnez-vous des rythmes
          vibrants et des performances passionnées de danseurs, chanteurs et
          musiciens de renommée mondiale, et découvrez l&#39;essence même de la
          culture espagnole.
        </MediumParagraph>
        <Spacer mt={{ xs: 10 }} />
        <MediumParagraph textAlign="center">
          Que vous soyez local ou visiteur, notre{" "}
          <strong>spectacle de flamenco</strong> vous offre une atmosphère
          unique et intime où vous pourrez apprécier l&#39;art du flamenco de
          près. Choisissez parmi nos zones de sièges premium et profitez
          d&#39;une boisson offerte, avec la sangria comme notre spécialité pour
          parfaire votre soirée.
        </MediumParagraph>
        <Spacer mt={{ xs: 10 }} />
        <MediumParagraph textAlign="center">
          Ne manquez pas cette expérience inoubliable. Réservez vos billets pour
          El Duende by Tablao Cordobés dès aujourd&#39;hui et assurez votre
          place pour l&#39;un des{" "}
          <strong>meilleurs spectacles de flamenco de Barcelone</strong>. Places
          limitées pour une expérience intime et immersive—réservez maintenant !
        </MediumParagraph>
      </>
    );
  }
  if (locale === LOCALES.SPANISH) {
    return (
      <>
        <MediumParagraph textAlign="center">
          Adéntrate en el mundo del flamenco en{" "}
          <strong>El Duende by Tablao Cordobés</strong>, un lugar auténtico
          ubicado en el corazón de La Rambla, Barcelona. Sumérgete en los
          vibrantes ritmos y apasionadas actuaciones de bailarines, cantantes y
          músicos de clase mundial, y vive la verdadera esencia de la cultura
          española.
        </MediumParagraph>
        <Spacer mt={{ xs: 10 }} />
        <MediumParagraph textAlign="center">
          Ya seas local o visitante, nuestro{" "}
          <strong>espectáculo de flamenco</strong> ofrece una atmósfera única e
          íntima donde podrás disfrutar del arte del flamenco de cerca. Elige
          entre nuestras zonas de asientos premium y disfruta de una bebida de
          cortesía, con la Sangría como nuestra oferta estrella para completar
          tu velada.
        </MediumParagraph>
        <Spacer mt={{ xs: 10 }} />
        <MediumParagraph>
          No te pierdas esta experiencia inolvidable. Reserva tus entradas para
          El Duende by Tablao Cordobés hoy mismo y asegura tu lugar en uno de
          los <strong>mejores espectáculos de flamenco de Barcelona</strong>.
          Plazas limitadas para una experiencia íntima e inmersiva. ¡Reserva
          ahora!
        </MediumParagraph>
      </>
    );
  }

  //todo: add japanese translation
  if (locale === LOCALES.JAPANESE) {
    return <></>;
  }

  //todo: add italian translation
  if (locale === LOCALES.ITALIAN) {
    return <></>;
  }

  return (
    <>
      <MediumParagraph textAlign="center">
        Step into the world of flamenco at{" "}
        <strong>El Duende by Tablao Cordobés</strong>, an authentic venue
        located in the heart of La Rambla, Barcelona. Immerse yourself in the
        vibrant rhythms and passionate performances of world-class dancers,
        singers, and musicians, and experience the true essence of Spanish
        culture.
      </MediumParagraph>
      <Spacer mt={{ xs: 10 }} />
      <MediumParagraph textAlign="center">
        Whether you&#39;re a local or a visitor, our{" "}
        <strong>flamenco show</strong>
        offers a unique, intimate atmosphere where you can enjoy the artistry of
        flamenco up close. Choose from premium seating zones and enjoy a
        complimentary drink, with Sangria being our signature offering to
        complete your evening.
      </MediumParagraph>
      <Spacer mt={{ xs: 10 }} />
      <MediumParagraph textAlign="center">
        Don't miss out on this unforgettable experience. Book your tickets to El
        Duende by Tablao Cordobés today and secure your place at one of{" "}
        <strong>Barcelona&#39;s top flamenco shows</strong>. Limited seating
        available for an intimate, immersive experience—reserve now!
      </MediumParagraph>
    </>
  );
};
