import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../env";

export const LOCATIONS = ["tablao", "duende"];

type PaymentMethodType = "PAYPAL" | "REDSYS";

type PromoCodeRequest = {
  promoCode: string;
  eventId: number;
  priceId: number;
  currentPrice: number;
};

type PromoCodeResponse = {
  newPrice: number;
  success: boolean;
};

type AvailabilityRequest = {
  people: string;
  dateFrom?: string;
  dateTo?: string;
  locations: string[];
};

export type PriceType = {
  price: number;
  ticketsAvailable: number;
  priceId: number;
  initialTickets: number;
  priceName: string;
  promo: boolean;
};

export type SingleShowType = {
  date: string;
  eventId: number;
  showTime: string;
  eventName: string;
  description: string;
  totalTicketsAvailable: number;
  eventType: string;
  place: string;
  prices: PriceType[];
  hasSecondLocation?: boolean;
  allowSinglePerson?: number;
};

type AvailabilityResponse = {
  availabilities: SingleShowType[];
};

export type ReservationRequest = {
  eventId: number;
  people: number;
  paymentMethod: string;
  joinTo?: string;
  email: string;
  name: string;
  phone: string;
  nationality: string;
  comments: string;
  wheelchair: string;
  vegan: string;
  priceId: number;
  location: string;
  languageCode: string;
  drinks?: Record<string, number>;
  hotel?: string;
  clientId: string;
  nif?: string;
  promoCode?: string;
};

export type ReservationResponse = {
  reservationReference?: string;
  paymentInfo?: {
    amount: number;
    form?: {
      Ds_MerchantParameters: string;
      Ds_Signature: string;
      Ds_SignatureVersion: string;
    };
    url?: string;
    method: PaymentMethodType;
  };
  errorMessage?: string;
  errorCode?: string;
};

export type FormInfoRequest = {
  adultTickets: number;
  childTickets: number;
  email: string;
  name: string;
  phone: string;
  nationality: string;
  languageCode: string;
  comments: string;
  wheelchair: string;
  vegan: string;
  priceOption: PriceType;
  activeProduct: SingleShowType;
  drinks?: Record<string, number>;
  hotel?: string;
  nif?: string;
};

export type FormInfoResponse = {
  success: boolean;
};

export type CartDetailsResponse = FormInfoRequest;
export type CartDetailsRequest = {
  id: string;
};
export type SendCartDetailsRequest = {
  eventId: number;
  priceId: number;
  adultTickets: number;
  childTickets: number;
  email: string;
  name: string;
  phone: string;
  nationality: string;
  comments: string;
  wheelchair: "0" | "1";
  vegan: "0" | "1";
  languageCode: string;
  eventName: string;
  eventDate: string;
  totalPrice: number;
  priceName: string;
  location: string;
  drinks?: Record<string, number>;
  hotel?: string;
  nif?: string;
};

export type SendCartDetailsResponse = FormInfoResponse;

export const bookingApi = createApi({
  reducerPath: "booking",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getAvailability: builder.query<AvailabilityResponse, AvailabilityRequest>({
      query: (params) => {
        if (params.locations.length > 1) {
          return {
            url: `/get-availability?locations=${params.locations[0]}&locations=${params.locations[1]}&people=${params.people}&dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`,
          };
        }
        return {
          url: `/get-availability?locations=${params.locations[0]}&people=${params.people}&dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`,
        };
      },
    }),
    reserve: builder.mutation<ReservationResponse, ReservationRequest>({
      query: (body) => ({
        url: "/reserve",
        method: "POST",
        body,
      }),
    }),
    formInfo: builder.mutation<FormInfoResponse, FormInfoRequest>({
      query: (body) => ({
        url: "/form-info",
        method: "POST",
        body,
      }),
    }),
    getCartDetails: builder.query<CartDetailsResponse, CartDetailsRequest>({
      query: (params) => ({
        url: `/cart?id=${params.id}`,
      }),
    }),
    sendCartDetails: builder.mutation<
      SendCartDetailsResponse,
      SendCartDetailsRequest
    >({
      query: (body) => ({
        url: "/cart",
        method: "POST",
        body,
      }),
    }),
    promo: builder.mutation<PromoCodeResponse, PromoCodeRequest>({
      query: (body) => ({
        url: "/promo",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAvailabilityQuery,
  useReserveMutation,
  useSendCartDetailsMutation,
  useGetCartDetailsQuery,
  usePromoMutation,
} = bookingApi;
