import { BookingElement, Line } from "./styles";
import {
  BigHeadline,
  BigLabel,
  MediumLabel,
  MediumParagraph,
  SectionHeadline,
  SmallHeadline,
  SmallParagraph,
  SummaryLabelSmall,
} from "../../typography.styles";
import { FormattedMessage, useIntl } from "react-intl";
import utc from "dayjs/plugin/utc";
import { Spacer } from "../../spacer";
import { FlexContent } from "../../FlexContent/FlexContent";
import { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../../providers/BookingProvider";
import { FinalizeBooking } from "../../finalizeBooking";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledSummaryBox, SummaryInfo, SummaryBill } from "./Summary.styles";
import { content } from "../../../i18n/content";
import { BOOKING_STEPS, STEP_SUMMARY } from "../../../consts";
import { Loader } from "components/loader";
import { drinkVariants } from "i18n/drinks";
import { PromoCode } from "components/promoCode";

dayjs.extend(utc);
export const Summary = () => {
  const { locale } = useIntl();
  const navigate = useNavigate();

  const {
    isDuende,
    adultTickets,
    childTickets,
    priceOption,
    activeProduct,
    setActiveStep,
    drinks,
  } = useContext(BookingContext);
  const { search } = useLocation();
  const [promoPrice, setPromoPrice] = useState<number | null>(null);
  const params = Object.fromEntries(new URLSearchParams(search));

  useEffect(() => {
    setActiveStep(BOOKING_STEPS.indexOf(STEP_SUMMARY));
  }, []);

  if ((!activeProduct || !adultTickets || !priceOption) && !!!params.cartId) {
    navigate("/");
    return null;
  }

  const flagEnabled = false;

  if ((params.isCart && !activeProduct) || !priceOption) {
    return <Loader />;
  }

  return (
    <>
      <SectionHeadline>
        {isDuende || activeProduct.eventType === "show_and_drink"
          ? "5. "
          : "4. "}
        <FormattedMessage
          defaultMessage="Booking summary and payment"
          id="O/IJC/"
        />
      </SectionHeadline>
      <Spacer mt={{ xs: 16, md: 32 }} />
      <BookingElement>
        <StyledSummaryBox>
          <SummaryInfo>
            <BigHeadline isSerifFont>
              {isDuende ? "El Duende Barcelona" : "Tablao Flamenco Cordobes"}
            </BigHeadline>
            <MediumParagraph>La Rambla 35, 08002 Barcelona</MediumParagraph>
            <Spacer mt={{ xs: 16, md: 32 }} />
          </SummaryInfo>
          <BookingElement>
            <FlexContent
              fullWidth
              justify="space-between"
              alignItems="flex-start"
            >
              <FlexContent direction="column" alignItems="flex-start">
                <BigLabel fontWeight={700}>
                  <FormattedMessage defaultMessage="Tickets for" id="DiGl2T" />{" "}
                  {content[locale][activeProduct.eventType]}
                </BigLabel>
                <Spacer mt={{ xs: 8, md: 16 }} />
                <MediumLabel style={{ textTransform: "capitalize" }}>
                  {dayjs(activeProduct.date).format("dddd, DD MMM, YYYY")}
                </MediumLabel>
                <MediumLabel>
                  <FormattedMessage defaultMessage="Starts at" id="9tuudg" />{" "}
                  {dayjs(activeProduct.date).format("HH:mm")}
                </MediumLabel>
              </FlexContent>
              {isDuende && (
                <FlexContent direction="column" alignItems="flex-start">
                  <SummaryLabelSmall>
                    {adultTickets}{" "}
                    {adultTickets > 1 ? (
                      <FormattedMessage defaultMessage="adults" id="hJmPCS" />
                    ) : (
                      <FormattedMessage defaultMessage="adult" id="8d6Mj3" />
                    )}
                  </SummaryLabelSmall>
                  {childTickets > 0 && (
                    <SummaryLabelSmall>
                      {childTickets}{" "}
                      {childTickets > 1 ? (
                        <FormattedMessage
                          defaultMessage="children"
                          id="T18R8v"
                        />
                      ) : (
                        <FormattedMessage defaultMessage="child" id="ky/4a0" />
                      )}
                    </SummaryLabelSmall>
                  )}
                  {priceOption.priceName !== "default" && (
                    <>
                      <Spacer mt={{ xs: 16, md: 16 }} />
                      <SummaryLabelSmall isUpperCase>
                        {priceOption.priceName}
                      </SummaryLabelSmall>
                    </>
                  )}
                </FlexContent>
              )}
            </FlexContent>
          </BookingElement>
        </StyledSummaryBox>
      </BookingElement>
      <Spacer mt={{ xs: 24 }} />
      <SummaryBill>
        <FlexContent justify="space-between" fullWidth>
          <BigLabel>
            <FormattedMessage defaultMessage="Price" id="b1zuN9" />
          </BigLabel>
          <SmallHeadline>{`${priceOption.price} euro`}</SmallHeadline>
        </FlexContent>
        <Spacer mt={{ xs: 16, md: 32 }} />
        <BigLabel>
          <FormattedMessage defaultMessage="Tickets" id="wbjZNA" />
        </BigLabel>
        <Spacer mt={{ xs: 16, md: 32 }} />
        <FlexContent justify="space-between" fullWidth padding="0 0 0 40px">
          <BigLabel>
            <FormattedMessage defaultMessage="Adults" id="rePX4c" />
          </BigLabel>
          <SmallHeadline>{adultTickets}</SmallHeadline>
        </FlexContent>
        <Spacer mt={{ xs: 8 }} />
        <FlexContent justify="space-between" fullWidth padding="0 0 0 40px">
          <BigLabel>
            <FormattedMessage defaultMessage="Children below 8*" id="dNuhJ9" />
          </BigLabel>

          <SmallHeadline>{childTickets}</SmallHeadline>
        </FlexContent>
        <FlexContent justify="space-between" fullWidth padding="0 0 0 40px">
          <SmallParagraph style={{ maxWidth: "calc(100% - 30px)" }}>
            <FormattedMessage
              defaultMessage="*Children between 4 and 8 years old will benefit from a 50% reduction
          in the ticket rate, redeemable only at check-in after showing proof of
          age."
              id="oCiK0K"
            />
          </SmallParagraph>
        </FlexContent>

        <Spacer mt={{ xs: 40 }} />
        <PromoCode setPromoPrice={setPromoPrice} />

        {!!flagEnabled && (
          <>
            <Spacer mt={{ xs: 16, md: 32 }} />
            <BigLabel>
              <FormattedMessage defaultMessage="Selected drinks" id="LP36/U" />
            </BigLabel>
            <Spacer mt={{ xs: 8, md: 24 }} />
            {drinks.map((drink) => (
              <>
                <Spacer mt={{ xs: 8 }} />
                <FlexContent
                  justify="space-between"
                  fullWidth
                  padding="0 0 0 40px"
                >
                  <BigLabel>
                    {
                      drinkVariants[locale].find(
                        (variant) => variant.value === Object.keys(drink)[0],
                      )?.label
                    }
                  </BigLabel>
                  <SmallHeadline>{Object.values(drink)[0]}</SmallHeadline>
                </FlexContent>
              </>
            ))}
          </>
        )}

        <Spacer mt={{ xs: 16, md: 32 }} />
        <Spacer mt={{ xs: 32 }} />
        <Line />
        <Spacer mt={{ xs: 24 }} />
        <FlexContent justify="space-between" fullWidth>
          <BigLabel>
            <FormattedMessage defaultMessage="Total" id="MJ2jZQ" />
          </BigLabel>

          <FlexContent>
            <BigHeadline>
              <span
                style={{
                  textDecoration: promoPrice ? "line-through" : "default",
                }}
              >{`${
                (adultTickets + childTickets) * priceOption.price
              } euro`}</span>
            </BigHeadline>
            {promoPrice && (
              <div style={{ marginLeft: "10px" }}>
                <BigHeadline>
                  <span>{promoPrice} euro</span>
                </BigHeadline>
              </div>
            )}
          </FlexContent>
        </FlexContent>
      </SummaryBill>
      <FinalizeBooking />
    </>
  );
};
